import React, { useContext, useEffect, useState } from "react";
import { submitFine } from "../actions";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "../CommonComponents/TextField";
import { AuthContext } from "../context/authcontext";
import Dropdown from "../CommonComponents/Dropdown";
import Upload from "../CommonComponents/Upload";
import axios from "axios";
import { toast } from "react-toastify";
import AppConstrains from "../constrains/AppConstrains";

import Slider from "@mui/material/Slider";
import Progressbar from "../CommonComponents/Progressbar";
import Loading from "../CommonComponents/loading/loading";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  height: "80vh",
  bgcolor: "#FFF",
  maxWidth: "800px",
  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const MortgageManagementModal = ({ rowData, setOpen }) => {
  const [loading, setLoading] = useState(false);
  const { fetchAgain, setFetchAgain } = useContext(AuthContext);
  const [pic1, setPic1] = useState(null);
  const [pic2, setPic2] = useState(null);
  const [mortageDetails, setMortageDetails] = useState({
    id: "",
    amount: "",
    mobile_no: "",
    aadhar_no: "",
    father_name: "",
    ref_name: "",
    signature_img: "",
    description: "",
    start_date: "",
    due_date: "",
    item_img_url: "",
    name: "",
    interest_percentage: "",
    interest_amt: "",
  });
  const clearAll = () => {
    setMortageDetails({
      amount: "",
      mobile_no: "",
      aadhar_no: "",
      father_name: "",
      ref_name: "",
      signature_img: "",
      description: "",
      start_date: "",
      due_date: "",
      item_img_url: "",
      name: "",
      interest_percentage: "",
      interest_amt: "",
    });
    setPic1(null);
    setPic2(null);
  };

  const picUpload1 = (e) => {
    const [file] = e.target.files;
    // console.log("pic uploading", file);
    setPic1(URL.createObjectURL(file));
    setMortageDetails({
      ...mortageDetails,
      signature_img: file,
    });
    // setFile(file);
  };

  const picUpload2 = (e) => {
    const [file] = e.target.files;
    // console.log("pic uploading", file);
    setPic2(URL.createObjectURL(file));
    setMortageDetails({
      ...mortageDetails,
      item_img_url: file,
    });
    // setFile(file);
  };
  useEffect(() => {
    if (rowData) {
      setMortageDetails({
        id: rowData.id || "",
        amount: rowData.amount || "",
        mobile_no: rowData.mobile_no || "",
        aadhar_no: rowData.aadhar_no || "",
        father_name: rowData.father_name || "",
        ref_name: rowData.ref_name || "",
        signature_img: rowData.signature_img || "",
        description: rowData.description || "",
        start_date: rowData.start_date || "",
        due_date: rowData.due_date || "",
        item_img_url: rowData.item_img_url || "",
        name: rowData.name || "",
        interest_percentage: rowData.interest_percentage || "",
        paid: rowData?.amount,
        interest_percentage: rowData?.interest_percentage,
      });
    } else {
      clearAll();
    }
  }, [rowData]);
  // console.log(rowData, "rowData");
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value, "name,value");
    setMortageDetails((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    if (mortageDetails?.amount && mortageDetails?.interest_percentage) {
      let interest_amt = parseFloat(
        (parseFloat(mortageDetails?.amount) *
          parseFloat(mortageDetails?.interest_percentage)) /
          100
      );

      setMortageDetails((prevDetails) => ({
        ...prevDetails,
        interest_amt: interest_amt,
      }));
    }
  }, [mortageDetails?.amount, mortageDetails?.interest_percentage]);

  const handleSubmit = async (e) => {
    // e.preventDefault();

    // Check if the start date is greater than the due date
    if (mortageDetails.start_date > mortageDetails.due_date) {
      toast.error("Start date cannot be greater than end date");
      return;
    }
    const formData = new FormData();
    Object.keys(mortageDetails).forEach((key) => {
      formData.append(key, mortageDetails[key]);
    });
    formData.forEach((value, key) => {
      // console.log(key, value, "key value");
    });
    const url = rowData
      ? `${process.env.REACT_APP_SERVER_HOST}` +
        "api/accounts/updatemortageinfo"
      : `${process.env.REACT_APP_SERVER_HOST}` + "api/accounts/addmortageinfo";
    try {
      const response = await axios.post(url, formData);
      if (response.data.code === 200) {
        toast.success(response.data.message);
        setOpen(false);
        setFetchAgain((prev) => !prev);
        clearAll();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Box sx={style} className="!w-[90%] sm:!w-[80%] lg:!w-full">
      {loading ? (
        <div className="rounded duration-100 fixed bg-[#212121]/70 left-0 top-0 right-0 bottom-0 z-[100] flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col items-center gap-4 sm:p-[32px] p-4 capitalize">
          <h1 className="text-[18px] ">
            {rowData ? "Update Mortgage" : "New Mortgage"}
          </h1>
          <div className="flex flex-col gap-[8px]">
            <div>
              <div className="formLayout">
                <div className="modalFormDivs !flex-col sm:!flex-row">
                  <TextField
                    label="Name"
                    placeholder="Ajay Soni"
                    type="text"
                    name={"name"}
                    value={mortageDetails.name}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Amount"
                    placeholder="Rs 10000"
                    type="text"
                    onChange={(e) => {
                      const value = e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1");

                      setMortageDetails({
                        ...mortageDetails,
                        [e.target.name]: value,
                      });
                    }}
                    inputProps={{
                      // maxLength: 12,
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                    }}
                    editable={rowData?.paid_amount ? false : true}
                    name={"amount"}
                    value={mortageDetails.amount}
                    // onChange={handleChange}
                  />
                </div>
                <div className="modalFormDivs !flex-col sm:!flex-row">
                  <TextField
                    label="Mobile No."
                    placeholder="Mobile no."
                    type="text"
                    name="mobile_no"
                    value={mortageDetails?.mobile_no}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      if (value.length <= 10) {
                        setMortageDetails({
                          ...mortageDetails,
                          [e.target.name]: value,
                        });
                      }
                    }}
                    inputProps={{
                      maxLength: 12,
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                    }}
                  />
                  <TextField
                    label="Aadhar No."
                    placeholder="aadhar no"
                    type="text"
                    name="aadhar_no"
                    value={mortageDetails?.aadhar_no}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      if (value.length <= 12) {
                        setMortageDetails({
                          ...mortageDetails,
                          [e.target.name]: value,
                        });
                      }
                    }}
                    inputProps={{
                      maxLength: 12,
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                    }}
                  />
                </div>
                <div className="modalFormDivs !flex-col sm:!flex-row">
                  <Upload
                    label="Upload signature"
                    name={"signature_img"}
                    // placeholder="7485691458"
                    type="file"
                    handleChnage={picUpload1}
                  />
                </div>
                <div className="flex justify-end w-full rounded">
                  {pic1 ? (
                    <img src={pic1} className="size-12" />
                  ) : rowData?.signature_img ? (
                    <img
                      className="size-10"
                      src={`${AppConstrains.baseURL}/imagePath/${rowData?.signature_img}`}
                      alt="logo"
                    />
                  ) : (
                    <p>Upload Image</p>
                  )}
                </div>
                <div className="modalFormDivs !flex-col sm:!flex-row">
                  <TextField
                    label="Reference Name"
                    placeholder="Enter Description"
                    type="text"
                    name={"ref_name"}
                    value={mortageDetails.ref_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="modalFormDivs !flex-col sm:!flex-row">
                  {" "}
                  <TextField
                    label="Father's Name"
                    placeholder="Enter Description"
                    type="text"
                    name={"father_name"}
                    value={mortageDetails.father_name}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Description"
                    placeholder="Enter Description"
                    type="text"
                    name={"description"}
                    value={mortageDetails.description}
                    onChange={handleChange}
                  />
                </div>

                {/* <div className="modalFormDivs"></div> */}
                {/* <div className="modalFormDivs">
                <Dropdown
                  label="Type"
                  placeholder="eg 50g"
                  optionarr={["credit", "debit"]}
                />
                <TextField
                  label="Fine Deposited"
                  placeholder="eg 50g"
                  type="number"
                />
              </div> */}

                <div className="modalFormDivs">
                  <Upload
                    label="Upload image"
                    name={"item_img_url"}
                    // placeholder="7485691458"
                    type="file"
                    handleChnage={picUpload2}
                  />
                </div>
                <div className="flex justify-end w-full rounded">
                  {pic2 ? (
                    <img src={pic2} className="size-12" />
                  ) : rowData?.item_img_url ? (
                    <img
                      className="size-10"
                      src={`${AppConstrains.baseURL}/imagePath/${rowData?.item_img_url}`}
                      alt="logo"
                    />
                  ) : (
                    <p>Upload Image</p>
                  )}
                </div>
                {/* <hr className="h-[2px] bg-[#000] opacity-20 w-full" /> */}
                <div className="modalFormDivs !flex-col sm:!flex-row">
                  <TextField
                    label="Interest Percentage"
                    placeholder="eg. 8%"
                    type="text"
                    onChange={(e) => {
                      const value = e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1");

                      setMortageDetails({
                        ...mortageDetails,
                        [e.target.name]: value,
                      });
                    }}
                    inputProps={{
                      // maxLength: 12,
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                    }}
                    // editable={false}
                    name={"interest_percentage"}
                    value={mortageDetails.interest_percentage}
                    // disabled={!!mortageDetails.paid_amount}
                    // onChange={handleChange}
                  />
                  <TextField
                    label="Interest Amount"
                    type="text"
                    onChange={(e) => {
                      const value = e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1");

                      setMortageDetails({
                        ...mortageDetails,
                        [e.target.name]: value,
                      });
                    }}
                    inputProps={{
                      // maxLength: 12,
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                    }}
                    name={"interest_amt"}
                    editable={false}
                    value={mortageDetails.interest_amt}
                    // onChange={handleChange}
                  />
                </div>
                <div className="modalFormDivs !flex-col sm:!flex-row">
                  <TextField
                    label="Start Date"
                    placeholder="Enter Date"
                    type="date"
                    name={"start_date"}
                    value={mortageDetails.start_date}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Due Date"
                    placeholder="Enter Date"
                    type="date"
                    name={"due_date"}
                    value={mortageDetails.due_date}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex w-full gap-1">
                  <button
                    className="discardBtn"
                    onClick={() => {
                      setOpen(false);
                      clearAll();
                    }}
                  >
                    Discard
                  </button>
                  <button className="submitBtn" onClick={handleSubmit}>
                    {rowData ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default MortgageManagementModal;

{
  /* <div className="modalFormDivs justify-around !items-center">
                <Box sx={{ width: 300 }}>
                  <Slider
                    max={1000}
                    disabled
                    defaultValue={300}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    className="!text-[#000]"
                  />
                  <p className="text-[16px] text-[#999]">
                    60% of amount is paid
                  </p>
                </div>
                <div className="btnOutlined">
                  <p>Add Installments</p>
                </div>
              </div> */
}
{
  /* <div className="modalFormDivs">
                <TextField
                  label="Amount Paid now"
                  placeholder="Rs"
                  type="number"
                />
              </div> */
}
{
  /* <div className="modalFormDivs">
                <TextField label="Name" placeholder="Eg Amit " type="text" />
                <Dropdown
                  label=" Customern Type"
                  placeholder=""
                  optionarr={["vendor", "client", "customer"]}
                />
              </div> */
}
{
  /* 
              <div className="modalFormDivs">
                <TextField
                  label="Date of Installment"
                  placeholder="Rs"
                  type="date"
                />
              </div> */
}
{
  /* <div className="modalFormDivs !flex-col sm:!flex-row">
                <Dropdown
                  optionarr={["Simple Interest", "Compound Interest"]}
                  label="Interest Type"
                  placeholder=""
                />
                <TextField
                  label="Interest Percentage"
                  placeholder="8%"
                  type="number"
                />
              </div>
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <TextField
                  label="Total Interest Amount"
                  // placeholder="R"
                  type="number"
                />
              </div> */
}
