import React, { useContext, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import { AuthContext } from "../context/authcontext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  //   height: "40vh",
  bgcolor: "#FFF",

  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const Conformation = ({
  setConformationPopUpOpen,
  onConfirm = () => {},
  title = "",
  props = "",
}) => {
  // console.log(props, "prop");

  const [interestAmt, setInterestAmt] = useState("");
  const [interestPercentage, setInterestPercentage] = useState("");
  const [settlementDate, setSettlementDate] = useState("");
  const { fetchAgain, setFetchAgain, loading, setLoading } =
    useContext(AuthContext);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_HOST}` +
          "api/accounts/updatemortageinfo",
        {
          id: props,
          paid_interest_amount: interestAmt,
          paid_amount: interestPercentage,
          settlement_date: settlementDate,
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        onConfirm(props);
        setConformationPopUpOpen(false);
        setFetchAgain((prev) => !prev);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error updating mortgage details:", error);
    }
  };
  return (
    <Box sx={style}>
      <div className="flex flex-col items-center justify-center gap-4 p-5">
        <h1>{title}</h1>
        <div className="modalFormDivs !flex-col sm:!flex-row">
          <TextField
            label="Paid Amount"
            placeholder="eg. 8%"
            type="number"
            name={"interest_percentage"}
            value={interestPercentage}
            onChange={(e) => setInterestPercentage(e.target.value)}
            // value={mortageDetails.interest_percentage}
            // onChange={handleChange}
          />
          <TextField
            label="Paid Intrest Amount"
            placeholder="Enter Interest Amount"
            type="number"
            name={"interest_amt"}
            value={interestAmt}
            onChange={(e) => setInterestAmt(e.target.value)}
            // value={mortageDetails.interest_amt}
            // onChange={handleChange}
          />
        </div>
        <div className="modalFormDivs !flex-col sm:!flex-row w-full">
          <TextField
            className="w-full"
            label="Settlement Date"
            placeholder="Enter Interest Amount"
            type="date"
            name={"settlement_date"}
            value={settlementDate || ""}
            onChange={(e) => setSettlementDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            // value={mortageDetails.interest_amt}
            // onChange={handleChange}
          />
        </div>
        <div className="flex gap-10">
          <button
            className="bg-[#588b58]/50 px-4 py-2 font-semibold"
            onClick={handleSubmit}
          >
            Yes
          </button>
          <button
            className="bg-[#ae3628]/50 px-4 py-2 font-semibold"
            onClick={() => setConformationPopUpOpen(false)}
          >
            No
          </button>
        </div>
      </div>
    </Box>
  );
};

export default Conformation;
