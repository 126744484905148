import CustomTableComponent from "../CommonComponents/customTableComponent/CustomTableComponent";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import MortgageManangementModal from "../Modals/MortgageManagementModal";
import { formatDate } from "../CommonComponents/customTableComponent/tableAction";
import MortagageManagementPopUp from "../Popup/MortagageManagementPopUp";
import { AuthContext } from "../context/authcontext";
import AppConstrains from "../constrains/AppConstrains";
// const data = [
//   {
//     image: "/images/test.png",
//     name: "Bracelet",
//     date: "23 July 2023",
//     dueDate: "24 July 2023",
//     amount: "30000",
//     paid: "pending",
//     id: 1,
//   },
//   {
//     image: "/images/test.png",
//     name: "Nackles",
//     date: "24 July 2023",
//     dueDate: "24 July 2023",
//     amount: "35000",
//     paid: "paid",
//     id: 2,
//   },
// ];
const columns = [
  { id: 1, name: "id", label: "S.No" },
  {
    id: 15,
    name: "item_img_url",
    label: "Image",
    option: {
      sort: false,
      tablebodyCustom: (row, column) => {
        if (column.name === "item_img_url")
          return (
            <td className={`py-2 pr-10 `}>
              <div className="w-[40px]">
                <img
                  className=" hover:scale-[2] duration-100 hover:z-[1000] object-cover rounded"
                  src={`${AppConstrains.baseURL}/imagePath/${row[column.name]}`}
                  alt="logo"
                />
              </div>
            </td>
          );
        else {
          return <></>;
        }
      },
    },
  },
  { id: 2, name: "name", label: "Name" },
  {
    id: 3,
    name: "amount",
    label: "Amount",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "amount") {
          return <td className={`py-2 pr-24 `}>{`₹ ${row[column.name]}`}</td>;
        }
      },
    },
  },
  { id: 4, name: "mobile_no", label: "Phone Number" },
  { id: 5, name: "aadhar_no", label: "Aadhar Number" },
  { id: 6, name: "father_name", label: "Father's Name" },
  { id: 7, name: "ref_name", label: "Reference Name" },
  {
    id: 8,
    name: "signature_img",
    label: "Signature",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "signature_img") {
          // console.log(row[column.name], "image");
          return (
            <td className={`py-2 pr-10 `}>
              <div className="w-[40px]">
                <img
                  className=" hover:scale-[2] duration-100 hover:z-[1000] object-cover rounded"
                  src={`${AppConstrains.baseURL}/imagePath/${row[column.name]}`}
                  alt="logo"
                />
              </div>
            </td>
          );
        }
      },
    },
  },
  // { id: 8, name: "date", label: "Date" },
  { id: 9, name: "description", label: "Description" },
  {
    id: 10,
    name: "start_date",
    label: "Start Date",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "start_date") {
          return (
            <td className={`py-2 pr-24`}>{formatDate(row[column.name])}</td>
          );
        }
      },
    },
  },
  {
    id: 11,
    name: "due_date",
    label: "Due Date",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "due_date") {
          return (
            <td className={`py-2 pr-24`}>{formatDate(row[column.name])}</td>
          );
        }
      },
    },
  },
  { id: 12, name: "paid_amount", label: "Paid" },
  { id: 13, name: "interest_amt", label: "Interest Amount" },
  { id: 14, name: "interest_percentage", label: "Interest Percentage" },
];
const MortgageManagement = () => {
  // console.log(AppConstrains.baseURL, "AppConstrains.baseURL");
  const [pageNo, setPageNo] = useState(1);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [data, setData] = useState([]);
  const { fetchAgain, setFetchAgain, loading, setLoading } =
    useContext(AuthContext);

  useEffect(() => {
    const controller = new AbortController();
    const getAllItemApi = async () => {
      try {
        // console.log(pageNo, "page no");
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_HOST}` +
            "api/accounts/getmortageinfo",
          {
            page: pageNo,
            start_date: dateFrom,
            end_date: dateTo,
          }
        );
        // console.log(response, "mortgage response");
        if (response.data.code === 200) {
          setData(response.data.data);
        } else {
          setData([]);

          pageNo > 1
            ? setPageNo((prev) => prev - 1)
            : setPageNo((prev) => prev);
        }

        // console.log(response, "Mortgage response");

        setLoading(false);
      } catch (error) {
        console.log(error, "error in fetching Mortgage data");
        setLoading(false);
      }
    };

    getAllItemApi();

    return () => {
      controller.abort();
    };
  }, [fetchAgain, pageNo, dateTo]);
  return (
    <>
      <CustomTableComponent
        title="Mortgage Management"
        data={data}
        columns={columns}
        search={true}
        dateFilter={true}
        download={true}
        // columnShow={5}
        Module={MortgageManangementModal}
        PopUp={MortagageManagementPopUp}
        setPageNo={setPageNo}
        pageNo={pageNo}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
      />
    </>
  );
};

export default MortgageManagement;
