import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Filler,
} from "chart.js";
import { formatDate } from "../../../CommonComponents/customTableComponent/tableAction";

// Register the required components
ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Filler
);

export default function BasicArea({ data }) {
  console.log(data, "data in graph");
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartRef.current) {
        setChartWidth(chartRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  // Transform data into the required format
  const transformedData = React.useMemo(() => {
    if (!data || data.length === 0) return { labels: [], datasets: [] };

    // Sort the data by date
    const sortedData = [...data].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    const labels = sortedData?.map((item) =>
      formatDate(new Date(item?.date).toISOString().split("T")[0])
    );
    const quantities = sortedData.map((item) => item.quantity);

    const datasets = [
      {
        label: "Quantities",
        data: quantities,
        fill: true,
        backgroundColor: "#C4A84566",
        borderColor: "#C4A845",
      },
    ];

    return { labels, datasets };
  }, [data]);

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const displayOptions = {
    responsive: true,
    Filler: true,
    maintainAspectRatio: false,
    aspectRatio: chartWidth < 600 ? 1 : 3, // Adjust aspect ratio for smaller screens
    plugins: {
      legend: {
        labels: {
          font: {
            size: chartWidth < 600 ? 8 : 10,
          },
        },
        position: "top",
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: chartWidth < 600 ? 8 : 10,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: chartWidth < 600 ? 8 : 10,
          },
        },
      },
    },
  };
  return (
    <div
      ref={chartRef}
      className="w-full h-full relative min-h-[300px] max-h-[600px]"
    >
      <Line data={transformedData} options={displayOptions} />
    </div>
  );
}
