import React, { useContext, useEffect, useState } from "react";

import StockManagementModal from "../Modals/StockManagementModal";
import CustomTableComponent from "../CommonComponents/customTableComponent/CustomTableComponent";
import StockManagementSoldHistory from "../Popup/StockManagementSoldHistory";
import { formatDate } from "../CommonComponents/customTableComponent/tableAction";

import { IconButton, Tooltip } from "@mui/material";
import axios from "axios";
import { AuthContext } from "../context/authcontext";
import AppConstrains from "../constrains/AppConstrains";

// const data = [
//   {
//     id: 1,
//     image: "/images/test.png",
//     name: "Product A",
//     weight: 250,
//     date: "2024-07-15",
//     soldStatus: false,
//     category: "Gold",
//     ornamentCategory: "Necklaces",
//     ornamentSubCategory: "Jewelry",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   {
//     id: 2,
//     image: "/images/test.png",
//     name: "Product B",
//     weight: 180,
//     date: "2024-07-10",
//     soldStatus: true,
//     category: "Silver",
//     ornamentCategory: "Earrings",
//     ornamentSubCategory: "Jewellery",
//     description:
//       "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//   },
//   {
//     id: 3,
//     image: "/images/test.png",
//     name: "Product C",
//     weight: 300,
//     date: "2024-07-12",
//     soldStatus: false,
//     category: "Platinum",
//     ornamentCategory: "Bracelets",
//     ornamentSubCategory: "Accessories",
//     description:
//       "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//   },
// ];

const columns = [
  { id: 1, name: "id", label: "S.No" },

  {
    id: 10,
    name: "img_url",
    label: "Image",
    option: {
      sort: false,
      tablebodyCustom: (row, column) => {
        if (column?.name === "img_url")
          return (
            <td className={`py-2 pr-10 `}>
              <div className="w-[40px]">
                <img
                  className=" hover:scale-[2] duration-100 hover:z-[1000] object-cover rounded"
                  src={`${AppConstrains.baseURL}/imagePath/${
                    row[column?.name]
                  }`}
                  alt="logo"
                />
              </div>
            </td>
          );
      },
    },
  },
  { id: 2, name: "name", label: "Name" },
  {
    id: 3,
    name: "weight",
    label: "Weight",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "weight") {
          const weight = row[column.name];
          if (!weight) {
            return 0;
          }
          // console.log(weight, "weight");

          // Convert weight to a number and fix to 3 decimal places
          const weightNum = parseFloat(weight).toFixed(3);

          // Split into grams and decimal part
          const [grams, decimalPart] = weightNum.split(".");
          // console.log(grams, "grams", decimalPart, "decimal Part");
          // Convert decimal part to milligrams
          const milligrams = decimalPart ? parseInt(decimalPart) : 0;

          // console.log(milligrams, "mg", grams, "gm");
          let stringToDisplay = "";

          if (grams === "0" && milligrams === 0) {
            stringToDisplay = "0";
          } else if (grams !== "0" && milligrams === 0) {
            stringToDisplay = `${grams} gm`;
          } else if (grams === "0" && milligrams !== 0) {
            stringToDisplay = `${milligrams} mg`;
          } else {
            stringToDisplay = `${grams} gm ${milligrams} mg`;
          }
          return (
            <td className={`py-2 pr-10 `}>
              <div>{stringToDisplay}</div>
            </td>
          );
        }
      },
    },
  },
  {
    id: 4,
    name: "entry_date",
    label: "Date",
    option: {
      tablebodyCustom: (row, column) => {
        if (column?.name === "entry_date") {
          return (
            <td className={`py-2 pr-24`}>{formatDate(row[column?.name])}</td>
          );
        }
      },
    },
  },
  {
    id: 5,
    name: "sold_status",
    label: "Sold Status",
    option: {
      tablebodyCustom: (row, column, setOpen, setModalComponent) => {
        if (column?.name === "sold_status") {
          // console.log(row.id, "sold status");
          return (
            <td className={`py-2 pr-24`}>
              <div className="flex items-center justify-start gap-4 capitalize">
                {`${parseInt(row[column?.name]) === 0 ? "unsold" : "sold"}`}
                {parseInt(row[column?.name]) !== 0 ? (
                  <Tooltip title="sold History">
                    <IconButton
                      onClick={() => {
                        setModalComponent(
                          <StockManagementSoldHistory id={row.id} />
                        );
                        setOpen(true);
                      }}
                    >
                      <img src="/images/info.svg" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </td>
          );
        }
      },
    },
  },
  { id: 6, name: "category", label: "Category" },
  { id: 7, name: "ornament_category", label: "Ornament Category" },
  { id: 8, name: "ornament_sub_category", label: "Ornament Sub Category" },
  { id: 9, name: "description", label: "Description" },
];

const Stockmanagement = ({
  dateFilter = true,
  download = true,
  action = true,
  title = "Stock Management",
  columnShow = 5,
  selecting = false,
  setFilter = false,
  setSelectedItem = () => {},
  setSeletecedItemOpen = () => {},
}) => {
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  // console.log(setFilter, "setFilter");
  const [pageNo, setPageNo] = useState(1);
  const [nextFalse, setNextFalse] = useState(true);
  const [data, setData] = useState([]);
  const { fetchAgain, setFetchAgain, loading, setLoading } =
    useContext(AuthContext);

  useEffect(() => {
    const controller = new AbortController();
    const getAllStockApi = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_HOST}` +
            "api/stocks/getitemsinventory",
          {
            dateFilter: setFilter,
            page: pageNo,
            start_date: dateFrom,
            end_date: dateTo,
          }
        );
        // console.log(response, "stock response");
        if (response.data.code === 200) {
          // setNextFalse(true);
          setData(response.data.data);
        } else {
          // setNextFalse(false)
          setData([]);
          pageNo > 1
            ? setPageNo((prev) => prev - 1)
            : setPageNo((prev) => prev);
        }
        // setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error, "error in fetching stock data");
        setLoading(false);
      }
    };

    getAllStockApi();

    return () => {
      controller.abort();
    };
  }, [fetchAgain, pageNo, dateTo]);

  return (
    <>
      <CustomTableComponent
        title={title}
        data={data}
        columns={columns}
        search={true}
        dateFilter={dateFilter}
        download={download}
        // columnShow={columnShow}
        action={action}
        Module={StockManagementModal}
        PopUp={StockManagementSoldHistory}
        selecting={selecting}
        setSeletecedItemOpen={setSeletecedItemOpen}
        setSelectedItem={setSelectedItem}
        setPageNo={setPageNo}
        pageNo={pageNo}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
        // nextFalse={nextFalse}
      />
    </>
  );
};

export default Stockmanagement;
