import React, { useContext, useState } from "react";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  LineText,
  MutedLink,
  SubmitButton,
} from "./common";
import { Marginer } from "../marginer";
import { AccountContext } from "./accountContext";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthContext } from "../context/authcontext";
import { CleanHands } from "@mui/icons-material";

export function SignupForm(props) {
  const [userData, setUserData] = useState({});
  const { switchToSignin } = useContext(AccountContext);

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (e) => {
    const { name, email, password, cpassword } = userData;

    if (userData) {
      if (!name || !email || !password || !cpassword) {
        toast.error("All fields are required !");
        return;
      }
      if (userData.password !== userData.cpassword) {
        toast.error("Password do not match !");
        return;
      }
    }

    // console.log(process.env.SERVER_HOST,"env info")

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_HOST}` + "api/auth/register",
        { email, name, password, cpassword }
      );
      // console.log(response, "response");
      if (response.data.code === 200) {
        toast("Sucessfully register");
        switchToSignin();
        return;
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        // console.log(error.response.data.message, "error.response.message")
      }
    }
  };

  // console.log(userData, "userData");
  return (
    <BoxContainer>
      <FormContainer>
        <Input
          value={userData.name}
          name="name"
          type="text"
          placeholder="Full name"
          onChange={handleChange}
        />
        <Input
          value={userData.email}
          name="email"
          type="email"
          placeholder="Email"
          onChange={handleChange}
        />
        <Input
          value={userData.password}
          name="password"
          type="password"
          placeholder="Password"
          onChange={handleChange}
        />
        <Input
          value={userData.cpassword}
          name="cpassword"
          type="text"
          placeholder="Confirm password"
          onChange={handleChange}
        />
      </FormContainer>
      <Marginer direction="vertical" margin={10} />
      <SubmitButton type="submit" onClick={handleSubmit}>
        Signup
      </SubmitButton>
      <Marginer direction="vertical" margin="5px" />
      <LineText>
        Already have an account?{" "}
        <BoldLink onClick={switchToSignin} href="#">
          Signin
        </BoldLink>
      </LineText>
    </BoxContainer>
  );
}
