import React, { useContext, useState } from "react";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  LineText,
  MutedLink,
  SubmitButton,
} from "./common";
import { Marginer } from "../marginer";
import { AccountContext } from "./accountContext";
import { AuthContext } from "../context/authcontext";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../CommonComponents/loading/loading";

export function LoginForm(props) {
  const { switchToSignup } = useContext(AccountContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  // console.log(user, "user");
  const [userData, setUserData] = useState();
  const { setAuth } = useContext(AuthContext);

  const Navigate = useNavigate();

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };
  // console.log(process.env.REACT_APP_SERVER_HOST, "url login");

  const handleSubmit = async () => {
    const { email, password } = userData;

    if (!email || !password) {
      toast.error("All fields are required");
    }
    try {
      // console.log("in try......");
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_SERVER_HOST + "api/auth/login",
        { email, password },
        { withCredentials: true }
      );
      // console.log(response, "response");

      if (response.data.code === 200) {
        toast("Login sucessfully");
        setAuth(true);
        Navigate("/");
        return;
      }
    } catch (error) {
      if (error.response) {
        // console.log(error.response, "error response");
        toast.error(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  // console.log(userData, "userData");
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <BoxContainer>
          <FormContainer>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
            />
            <Input
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
            />
          </FormContainer>
          <Marginer direction="vertical" margin={10} />
          {/* <MutedLink href="#">Forget your password?</MutedLink> */}
          <Marginer direction="vertical" margin="1.6em" />
          <SubmitButton type="submit" onClick={handleSubmit}>
            Signin
          </SubmitButton>
          <Marginer direction="vertical" margin="5px" />
          <LineText>
            Don't have an accoun?{" "}
            <BoldLink onClick={switchToSignup} href="#">
              Signup
            </BoldLink>
          </LineText>
        </BoxContainer>
      )}
    </>
  );
}
