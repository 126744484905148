// import { TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import TextField from "../CommonComponents/TextField";
import { Modal } from "@mui/material";
const Account_management_stats = () => {
  const [open, setOpen] = useState(false);
  const startDate = new Date();
  const endDate = new Date();
  const [accountData, setAccountData] = useState({
    total_amount: null,
    total_amt_received: null,
    total_amt_received_credit: null,
    total_amt_spent: null,
  });
  const [lastAccountEntries, setLastAccountEntries] = useState([]);
  startDate.setDate(startDate.getDate() - 7);

  const [selectionRange, setSelectionRange] = useState({
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  });

  const dateConverter = (date) => {
    const arr = date.toString().split(" ");
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date_str =
      arr[3] +
      "-" +
      (months.indexOf(arr[1]) + 1).toString().padStart(2, "0") +
      "-" +
      arr[2];
    return date_str;
  };

  const fetchAccountData = async () => {
    if (selectionRange.startDate && selectionRange.endDate) {
      try {
        const fetchAccountData = await axios.post(
          `${process.env.REACT_APP_SERVER_HOST}` +
            "api/accounts/dashboardaccountdetails",
          {
            start_date: dateConverter(selectionRange.startDate),
            end_date: dateConverter(selectionRange.endDate),
          }
        );

        // console.log(fetchAccountData, "account  in apiData");
        setAccountData(fetchAccountData.data.data[0]);
        setLastAccountEntries(fetchAccountData.data.lastAccountEntries);
      } catch (error) {
        console.log(error, "error in fetch account data");
      }
    }
  };

  const handleSelect = (ranges) => {
    // console.log(ranges.selection, "selection date");
    // setStartDate(ranges.selection.startDate);
    // setEndDate(ranges.selection.endDate);

    // set;
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }

    setSelectionRange(ranges.selection);
  };

  useEffect(() => {
    fetchAccountData();
  }, [selectionRange.endDate]);
  // console.log(accountData, "accountData");
  // console.log(selectionRange, "selected Data in table");
  // console.log(dateConverter(selectionRange.startDate), "start Date");
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-wrap justify-between gap-y-2">
        <h3 className="text-[#030303] text-lg not-italic font-semibold leading-normal text-end">
          Account Management
        </h3>
        <Link to="account_management">
          <div className="flex gap-2 px-2 border border-solid border-[#333] rounded">
            <h3 className="text-right text-base not-italic font-medium leading-[28.8px]">
              Detailed View
            </h3>
            <img src="/images/add-icon.svg" alt="add icon" />
          </div>
        </Link>
      </div>

      <div className="bg-[#FEFEFE] rounded-lg px-4 sm:px-8 py-6 flex gap-8 max-[844px]:flex-col">
        <div className="py-[7px] flex flex-col justify-between items-center pr-8 !max-[845px]:border-none gap-y-4 border-r borderNone border-solid border-[#00000033]">
          <div
            className="flex-col w-full duration-100 cursor-pointer modalFormDivs sm:flex-row hover:scale-90"
            onClick={() => {
              setOpen(true);
            }}
          >
            <TextField
              name="Start Date"
              label="Start Date"
              type="date"
              editable={false}
              value={dateConverter(selectionRange.startDate)}

              // onChange={handleChnage}
            />
            <TextField
              name="End Date"
              label="End Date"
              type="date"
              editable={false}
              value={dateConverter(selectionRange.endDate)}
              // onChange={handleChnage}
            />
          </div>
          {/* <div className="flex gap-[10px] px-[19px] py-[6px] border border-solid border-[#D6D6D6] rounded"> */}
          {/* <h5 className="text-[#666] text-[10px] not-italic font-medium leading-normal">
              30 Days
            </h5> */}
          {/* <img src="/images/down-arrow.svg" alt="down-arrow" /> */}
          {/* </div> */}

          <div
            className={`flex flex-col ${
              accountData?.total_amt_received_credit -
                accountData?.total_amt_spent >
              0
                ? "text-[#588B58]"
                : "text-[#851f26]"
            } text-[40px] not-italic font-bold leading-[48px] items-center`}
          >
            <div className="flex gap-[6px]">
              {accountData?.total_amount ? (
                <h2>
                  &#8377;
                  {accountData?.total_amount}{" "}
                </h2>
              ) : (
                "Not found"
              )}
            </div>

            <h3 className="text-[#030303] text-center text-[12px] not-italic font-medium leading-[18px]">
              in plus total
            </h3>
          </div>

          <div className="px-4 py-2 sm:py-[11px] bg-[#F4F4F4] rounded-lg flex gap-3 md:gap-8">
            <div className="flex flex-col items-center pr-3 md:pr-8 border-r border-solid border-[#00000033] py-[6px]">
              {accountData?.total_amt_received_credit ? (
                <h3 className="text-[030303] text-center self-stretch text-lg not-italic font-semibold leading-normal">
                  &#8377;{accountData?.total_amt_received_credit}
                </h3>
              ) : (
                "Not Found"
              )}
              <div className="flex items-center justify-center">
                <h4 className="text-[#588B58] text-[12px] not-italic font-medium leading-[18px]">
                  received
                </h4>
                <img src="images/green-arrow.svg" alt="grren arrow" />
              </div>
            </div>
            <div className="flex flex-col items-center py-[6px]">
              {accountData?.total_amt_spent ? (
                <h3 className="text-[030303] text-center self-stretch text-lg not-italic font-semibold leading-normal">
                  &#8377;{accountData?.total_amt_spent}
                </h3>
              ) : (
                "Not Found"
              )}

              <div className="flex items-center justify-center">
                <h4 className="text-[#B15151] text-[12px] not-italic font-medium leading-[18px]">
                  spend
                </h4>
                <img src="images/red-arrow.svg" alt="grren arrow" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full gap-4 ">
          <h3 className="self-stretch text-[#030303] text-base not-italic font-medium leading-[28.8px]">
            Last 4 transactions
          </h3>

          <div className="relative flex flex-col w-full gap-4 py-2 overflow-x-auto">
            {lastAccountEntries.length > 0
              ? lastAccountEntries.map((data, index) => {
                  return (
                    <div
                      className={`flex justify-between break-all text-[#030303] pb-4 text-sm not-italic font-normal ${
                        index === 3 ? "" : "border-b"
                      }  border-solid border-[#03030326] leading-[21px] w-fit md:w-full`}
                    >
                      <h3 className="w-[20%] min-w-[130px] md:min-w-0">
                        {data?.user_name}
                      </h3>
                      <h3 className="w-[20%] min-w-[130px] md:min-w-0">
                        {data?.delivery_date}
                      </h3>
                      {data?.pay_debt_type === "credit" ? (
                        <h3 className="text-[#588B58] w-[20%] min-w-[130px] md:min-w-0">
                          {data?.pay_debt_type}
                        </h3>
                      ) : (
                        <h3 className="text-[#B15151] w-[20%] min-w-[130px] md:min-w-0">
                          {data?.pay_debt_type}
                        </h3>
                      )}
                      <h3 className="w-[20%] min-w-[130px] md:min-w-0">
                        &#8377;{data?.amt_received}
                      </h3>
                    </div>
                  );
                })
              : "No data available"}
          </div>
        </div>
      </div>
      <Modal
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        {/* {modalComponent} */}
        <div className="flex items-center justify-center m-auto border w-fit">
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={(ranges) => handleSelect(ranges)}
          />
        </div>

        {/* <></> */}
      </Modal>
    </div>
  );
};

export default Account_management_stats;
