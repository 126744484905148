import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import AppConstrains from "../constrains/AppConstrains";
import Loading from "../CommonComponents/loading/loading";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  // height: "80vh",
  bgcolor: "#FFF",
  maxWidth: 630,
  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const AccountManagementSoldItem = ({ id }) => {
  // console.log("Requesting item with ID:", id);
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);
  const weightCalculate = () => {
    let weight = item.weight;
    let stringToDisplay = "";

    if (!weight) {
      return stringToDisplay;
    }
    console.log(weight, "weight");

    // Convert weight to a number and fix to 3 decimal places
    const weightNum = parseFloat(weight).toFixed(3);

    // Split into grams and decimal part
    const [grams, decimalPart] = weightNum.split(".");
    console.log(grams, "grams", decimalPart, "decimal Part");
    // Convert decimal part to milligrams
    const milligrams = decimalPart ? parseInt(decimalPart) : 0;

    // console.log(milligrams, "mg", grams, "gm");

    if (grams === "0" && milligrams === 0) {
      stringToDisplay = "0";
    } else if (grams !== "0" && milligrams === 0) {
      stringToDisplay = `${grams} gm`;
    } else if (grams === "0" && milligrams !== 0) {
      stringToDisplay = `${milligrams} mg`;
    } else {
      stringToDisplay = `${grams} gm ${milligrams} mg`;
    }
    return stringToDisplay;
  };

  useEffect(() => {
    if (id) {
      const getItem = async () => {
        try {
          setLoading(true);
          const responseItem = await axios.post(
            `${process.env.REACT_APP_SERVER_HOST}` +
              "api/stocks/getitemdetails",
            { id }
            // {withCredentials:true}
          );
          // console.log("items : ", responseItem.data.data);
          setItem(responseItem.data.data);
          // console.log(item, "itemitem");
        } catch (error) {
          console.log(error);
          setItem({});
        } finally {
          setLoading(false);
        }
      };
      getItem();
    }
  }, [id]);

  return (
    <Box sx={style} className="!w-[90%] md:!w-full">
      <div className="flex flex-col justify-center">
        <h1 className="text-[18px] py-4 text-center">Sold item</h1>
        <div className="flex flex-col gap-4 p-3 sm:p-6">
          {loading ? (
            <Loading />
          ) : (
            <div className="flex px-4 sm:px-[40px] justify-between gap-20 h-[196px] bg-[#F4F4F4]">
              <div className="flex items-center justify-center">
                {/* <img className="sm:w-[180px] w-[80px]" src={`${item.img_url}`} /> */}
                <img
                  className=" object-cover rounded h-[80%]"
                  src={`${AppConstrains.baseURL}/imagePath/${item.img_url}`}
                  alt="logo"
                />
              </div>
              <div className="flex justify-start items-center w-[50%] ">
                <div className="flex flex-col gap-4 flex-nowrap text-nowrap">
                  <div className="flex flex-wrap justify-between gap-28 gap-y-1 md:flex-nowrap">
                    <div>Weight:</div>
                    <div className="text-wrap">{weightCalculate()}</div>
                  </div>
                  <div className="flex flex-wrap justify-between gap-28 gap-y-1 md:flex-nowrap">
                    <div>Name:</div>
                    <div className="text-wrap">{`${
                      item?.name ? `${item.name}` : "Item not found"
                    }`}</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <div className="w-full h-[1px] bg-black/10"></div> */}

          {/* <div>
              <CustomTableComponent
                //   title="Stock Management"
                data={data.slice(0, 10)}
                columns={columns}
                search={false}
                dateFilter={false}
                columnFilter={false}
                addNew={false}
                download={false}
                completeHeader={false}
                action={false}
                columnShow={4}
                //   Module={StockManagementModal}
                //   PopUp={StockManagementPopUp}
              />
            </div> */}
        </div>
      </div>
    </Box>
  );
};

export default AccountManagementSoldItem;
