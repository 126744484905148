import React, { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import FineManagementModal from "../Modals/FineManagementModal";
import CustomTableComponent from "../CommonComponents/customTableComponent/CustomTableComponent";
import { AuthContext } from "../context/authcontext";
import axios from "axios";
import { formatDate } from "../CommonComponents/customTableComponent/tableAction";
import AppConstrains from "../constrains/AppConstrains";

// const data = [
//   {
//     id: 1,
//     name: "Tanisha",
//     dueData: "24 July 2023",
//     giveTake: "gave",
//     weight: "23",
//     interest: "14",
//     paid: 200,
//   },
// ];

const columns = [
  { id: 1, name: "id", label: "S.No" },
  { id: 2, name: "vendor_name", label: "Vendor Name" },
  { id: 3, name: "mobile_no", label: "Mobile No" },
  {
    id: 4,
    name: "signature_img_url",
    label: "Signature",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "signature_img_url") {
          // console.log(row[column.name], "image");
          return (
            <td className={`py-2 pr-10 `}>
              <div className="w-[40px]">
                <img
                  className=" hover:scale-[2] duration-100 hover:z-[1000] object-cover rounded"
                  src={`${AppConstrains.baseURL}/imagePath/${row[column.name]}`}
                  alt="logo"
                />
              </div>
            </td>
          );
        }
      },
    },
  },
  { id: 6, name: "aadhar_no", label: "Aadhar No" },
  {
    id: 5,
    name: "weight",
    label: "Weight",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "weight") {
          const weight = row[column.name];
          if (!weight) {
            return 0;
          }
          // console.log(weight, "weight");

          // Convert weight to a number and fix to 3 decimal places
          const weightNum = parseFloat(weight).toFixed(3);

          // Split into grams and decimal part
          const [grams, decimalPart] = weightNum.split(".");
          // console.log(grams, "grams", decimalPart, "decimal Part");
          // Convert decimal part to milligrams
          const milligrams = decimalPart ? parseInt(decimalPart) : 0;

          // console.log(milligrams, "mg", grams, "gm");
          let stringToDisplay = "";

          if (grams === "0" && milligrams === 0) {
            stringToDisplay = "0";
          } else if (grams !== "0" && milligrams === 0) {
            stringToDisplay = `${grams} gm`;
          } else if (grams === "0" && milligrams !== 0) {
            stringToDisplay = `${milligrams} mg`;
          } else {
            stringToDisplay = `${grams} gm ${milligrams} mg`;
          }
          return (
            <td className={`py-2 pr-10 `}>
              <div>{stringToDisplay}</div>
            </td>
          );
        }
      },
    },
  },
  { id: 7, name: "tanch", label: "Tanch" },
  { id: 8, name: "type", label: "Type" },
  { id: 9, name: "fine_deposited", label: "Fine Deposited" },
  { id: 10, name: "lab_charge", label: "Labour Charge" },
  { id: 11, name: "lab_quantity", label: "Labour Quantity" },
  { id: 12, name: "ref_name", label: "Reference Name" },
  { id: 14, name: "description", label: "Description" },
  {
    id: 15,
    name: "entry_date",
    label: "Entry Date",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "entry_date") {
          return (
            <td className={`py-2 pr-24`}>{formatDate(row[column.name])}</td>
          );
        }
      },
    },
  },
  { id: 16, name: "item_name", label: "Item Name" },
  { id: 17, name: "fine", label: "Fine" },
  { id: 18, name: "fine_remaining", label: "Fine Remaining" },
  {
    id: 19,
    name: "item_img_url",
    label: "Item Image",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "item_img_url") {
          // console.log(row[column.name], "image");
          return (
            <td className={`py-2 pr-10 `}>
              <div className="w-[40px]">
                <img
                  className=" hover:scale-[2] duration-100 hover:z-[1000] object-cover rounded"
                  src={`${AppConstrains.baseURL}/imagePath/${row[column.name]}`}
                  alt="logo"
                />
              </div>
            </td>
          );
        }
      },
    },
  },
  {
    id: 20,
    name: "delievery_date",
    label: "Delievery Date",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "delievery_date") {
          return (
            <td className={`py-2 pr-24`}>{formatDate(row[column.name])}</td>
          );
        }
      },
    },
  },
];
const FineManagement = () => {
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [open, setOpen] = React.useState(false);
  const [pageNo, setPageNo] = useState(1);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([]);
  const { fetchAgain, setFetchAgain, loading, setLoading } =
    useContext(AuthContext);

  useEffect(() => {
    const controller = new AbortController();
    const getAllFineApi = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_HOST}` +
            "api/accounts/getfinedetails",
          {
            page: pageNo,
            start_date: dateFrom,
            end_date: dateTo,
          }
        );
        if (response.data.code === 200) {
          // console.log(response, "stock response");
          // data = ;
          setData(response.data.data);
        } else {
          setData([]);

          pageNo > 1
            ? setPageNo((prev) => prev - 1)
            : setPageNo((prev) => prev);
        }
        setLoading(false);
      } catch (error) {
        console.log(error, "error in fetching stock data");
        setLoading(false);
      }
    };

    getAllFineApi();

    return () => {
      controller.abort();
    };
  }, [fetchAgain, pageNo, dateTo]);
  return (
    <div>
      <CustomTableComponent
        title="Fine Management"
        data={data}
        columns={columns}
        search={true}
        dateFilter={true}
        download={true}
        // columnShow={4}
        setPageNo={setPageNo}
        pageNo={pageNo}
        Module={FineManagementModal}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
      />
    </div>
  );
};

export default FineManagement;
