import React, { useContext, useEffect, useState } from "react";
import CustomTableComponent from "../CommonComponents/customTableComponent/CustomTableComponent";
import { IconButton, Tooltip } from "@mui/material";

import DebitManagementModal from "../Modals/DebitManagementModal";
import { formatDate } from "../CommonComponents/customTableComponent/tableAction";
import axios from "axios";
import { AuthContext } from "../context/authcontext";
import DebtManagementPaidHistory from "../Popup/DebtManagementPaidHistory";

const columns = [
  { id: 1, name: "id", label: "S.No" },
  { id: 2, name: "user_name", label: "Name" },

  {
    id: 4,
    name: "amount",
    label: "Due Amount",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "amount") {
          return <td className={`py-2 pr-24`}>{`₹ ${row[column.name]} `}</td>;
        }
      },
    },
  },
  {
    id: 5,
    name: "intrest_percentage",
    label: "Interest",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "intrest_percentage") {
          return (
            <td className={`py-2 pr-24`}>{` ${
              row[column.name] ? row[column.name] : 0
            }% `}</td>
          );
        }
      },
    },
  },
  { id: 6, name: "interest_amt", label: "Interest Amount" },
  // {
  //   id: 7,
  //   name: "date",
  // dfds
  //   label: "Date",
  //   option: {
  //     tablebodyCustom: (row, column) => {
  //       if (column.name === "date") {
  //         return (
  //           <td className={`py-2 pr-24`}>{formatDate(row[column.name])}</td>
  //         );
  //       }
  //     },
  //   },
  // },
  {
    id: 8,
    name: "start_date",
    label: "Start Date",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "start_date") {
          return (
            <td className={`py-2 pr-24`}>{formatDate(row[column.name])}</td>
          );
        }
      },
    },
  },
  {
    id: 9,
    name: "due_end_date",
    label: "Due End Date",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "due_end_date") {
          return (
            <td className={`py-2 pr-24`}>{formatDate(row[column.name])}</td>
          );
        }
      },
    },
  },
  {
    id: 10,
    name: "paid_amount",
    label: "Paid",
    option: {
      tablebodyCustom: (row, column, setOpen, setModalComponent) => {
        if (column.name === "paid_amount") {
          // Use the nullish coalescing operator to set default to 0
          const paidAmount = row[column.name] ?? 0;
          // console.log(row.account_id, "rowwww");
          return (
            <td className={`py-2 pr-24`}>
              {" "}
              {paidAmount !== 0 ? (
                <>
                  <span>₹ {paidAmount}</span>
                  <IconButton
                    onClick={() => {
                      setModalComponent(
                        <DebtManagementPaidHistory id={row.id} />
                      );
                      setOpen(true);
                    }}
                  >
                    <img src="/images/info.svg" />
                  </IconButton>
                </>
              ) : (
                <span>₹ {paidAmount}</span>
              )}
            </td>
          );
        }
      },
    },
  },
  {
    id: 11,
    name: "total_interest_amt",
    label: "Total Interest Amount",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "total_interest_amt") {
          const total_interest_amt = row[column.name] ?? 0;
          return (
            <td className={`py-2 pr-24`}>
              {" "}
              {total_interest_amt !== 0 ? (
                <>
                  <span>₹ {total_interest_amt}</span>
                </>
              ) : (
                <span>₹0</span>
              )}
            </td>
          );
        }
      },
    },
  },
];
const DebtManagement = () => {
  const [pageNo, setPageNo] = useState(1);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const { fetchAgain, setFetchAgain, loading, setLoading } =
    useContext(AuthContext);

  const [data, setData] = useState([]);
  useEffect(() => {
    const controller = new AbortController();
    const getAllDebtkApi = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_HOST}` +
            "api/accounts/getdebtdetails",
          {
            page: pageNo,
            start_date: dateFrom,
            end_date: dateTo,
          }
        );
        // console.log(response, "Debt response");
        // data = ;
        if (response.data.code === 200) {
          setData(response.data.data);
        } else {
          setData([]);
          pageNo > 1
            ? setPageNo((prev) => prev - 1)
            : setPageNo((prev) => prev);
        }
        setLoading(false);
      } catch (error) {
        console.log(error, "erro in fetching Debt data");
        setLoading(false);
      }
    };

    getAllDebtkApi();

    return () => {
      controller.abort();
    };
  }, [fetchAgain, pageNo, dateTo]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <CustomTableComponent
        // key={updateTrigger}
        title="Debt Management"
        data={data}
        columns={columns}
        search={true}
        addNew={false}
        dateFilter={true}
        download={true}
        // columnShow={4}
        Module={DebitManagementModal}
        pageNo={pageNo}
        setPageNo={setPageNo}
        setOpen={setOpen}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
      />
    </>
  );
};

export default DebtManagement;
