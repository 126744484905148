import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { formatDate } from "../CommonComponents/customTableComponent/tableAction";
import Loading from "../CommonComponents/loading/loading";
// import TextField from "../CommonComponents/TextField";
// import Dropdown from "../CommonComponents/Dropdown";
// import Upload from "../CommonComponents/Upload";
// import CustomTableComponent from "../CommonComponents/customTableComponent/CustomTableComponent";

// const data = [
//   {
//     id: 1,
//     date: "24 June 2024",
//     paymentType: "Cash",
//     amount: "3208",
//     quantity: "10",
//   },
//   {
//     id: 1,
//     date: "24 June 2024",
//     paymentType: "Cash",
//     amount: "3208",
//     quantity: "10",
//   },
//   {
//     id: 1,
//     date: "24 June 2024",
//     paymentType: "Cash",
//     amount: "3208",
//     quantity: "10",
//   },
//   {
//     id: 1,
//     date: "24 June 2024",
//     paymentType: "Cash",
//     amount: "3208",
//     quantity: "10",
//   },
// ];

// const columns = [
//   { id: 1, name: "date", label: "Date" },
//   { id: 2, name: "paymentType", label: "Payment Type" },
//   { id: 3, name: "amount", label: "Amount" },
//   { id: 4, name: "quantity", label: "Quantity" },
// ];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  // height: "80vh",
  bgcolor: "#FFF",
  maxWidth: 550,

  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const StockManagementSoldHistory = ({ id, img_url }) => {
  // console.log(id, "id");
  // console.log(img_url, "img_url");
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id) {
      const fetchStockManagementSoldHistory = async () => {
        // console.log("Requesting item with ID:", id);
        try {
          setLoading(true);
          const responseItem = await axios.post(
            `${process.env.REACT_APP_SERVER_HOST}` +
              "api/accounts/getaccntinfoid",
            { item_id: id }
            // {withCredentials:true}
          );
          // console.log("items : ", responseItem.data.data);
          setItem(responseItem.data.data);
          // console.log(item, "itemitem");
        } catch (error) {
          console.log(error);
          setItem({});
        } finally {
          setLoading(false);
        }
      };

      fetchStockManagementSoldHistory();
    }
  }, [id]);

  return (
    <Box sx={style} className="!w-[90%] md:!w-full">
      <div className="flex flex-col items-center justify-center ">
        <h1 className="text-[18px] py-4">Sold history</h1>
        <div className="flex flex-col w-full gap-4 p-6">
          {loading ? (
            <Loading />
          ) : (
            <div className="flex px-4 py-3 sm:px-5 w-full justify-between sm:gap-20 gap-3 bg-[#F4F4F4]">
              <div className="flex items-center justify-between w-full">
                <div className="flex flex-col w-full gap-4 flex-nowrap text-nowrap">
                  <div className="flex flex-wrap justify-between gap-28 sm:flex-nowrap gap-y-1">
                    <div>Amount:</div>
                    <div className="text-wrap">{`${
                      item[0]?.amount ? `${item[0].amount}` : "Amount not found"
                    }`}</div>
                  </div>
                  <div className="flex flex-wrap justify-between gap-28 sm:flex-nowrap gap-y-1">
                    <div>Date:</div>
                    <div className="text-wrap">
                      {/* {`${item[0]?.entry_date ? `${item[0].entry_date.toLocaleDateString()}` : "Date not found"}`} */}
                      {item[0]?.entry_date ? (
                        <>
                          <div>
                            {new Date(item[0].entry_date).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </div>
                          <div>
                            {new Date(item[0].entry_date).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        "Date not found"
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-between gap-28 sm:flex-nowrap gap-y-1">
                    <div>Name of buyer:</div>
                    <div className="text-wrap">{`${
                      item[0]?.user_name
                        ? `${item[0].user_name}`
                        : "Name not found"
                    }`}</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <div className="w-full h-[1px] bg-black/10"></div> */}

          {/* <div>
            <CustomTableComponent
              //   title="Stock Management"
              data={data.slice(0, 10)}
              columns={columns}
              search={false}
              dateFilter={false}
              columnFilter={false}
              addNew={false}
              download={false}
              completeHeader={false}
              action={false}
              columnShow={4}
              //   Module={StockManagementModal}
              //   PopUp={StockManagementPopUp}
            />
          </div> */}
        </div>
      </div>
    </Box>
  );
};

export default StockManagementSoldHistory;
