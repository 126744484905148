export const formatDate = (dateString) => {
  // console.log(dateString, "dateString comming");
  if (!dateString) {
    return;
  }
  const [year, month, day] = dateString.split("-");

  const monthMap = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December",
  };
  return `${day} ${monthMap[month]} ${year}`;
};
