import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FineManagement from "./FineManagement/FineManagement";
import AccountManagement from "./AccountManagement/AccountManagement";
import Stockmanagement from "./StockManagement/Stockmanagement";
import DebtManagement from "./DebtManagement/DebtManagement";
import MortgageManagement from "./MortgageManagement/MortgageManagement";
import Dashboard from "./Dashboard/index";
import SideDrawer from "./Side drawer";
import AccountBox from "./accountBox";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { AuthContext } from "./context/authcontext";
import Logout from "./Logout";
import { useEffect } from "react";
import axios from "axios";
import Loading from "./CommonComponents/loading/loading";

function App() {
  const { auth, setAuth, loading, setLoading } = useContext(AuthContext);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        // console.log("in try......");
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_HOST}api/auth/getuserprofile`,
          {
            withCredentials: true,
          }
        );
        // console.log(response, "response");

        if (response.data.code === 200) {
          setAuth(1);
        }
      } catch (error) {
        if (error) {
          console.log(error, "error response");
          // console.log("rrrrrrrrrrrrrrrrrrrrrr");
        } else {
          console.error("An error occurred", error);
        }
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <Router>
      <div className="w-full App">
        {loading && (
          <div className="rounded duration-100 fixed bg-[#212121]/70 left-0 top-0 right-0 bottom-0 z-[100] flex justify-center items-center">
            <Loading />
          </div>
        )}
        <div className="w-full app-container">
          {auth ? <SideDrawer /> : <></>}
          {/* {auth ? (
        <div className="ml-[270px] pt-[70px] h-[100vh] relative">
          {loading && (
            <div className="rounded duration-100 absolute bg-[#212121]/70 left-0 right-0 h-[90%] z-[100] flex justify-center items-center">
              <Loading />
            </div>
          )}
         
        </div>
      ) : <></> } */}

          {auth ? (
            <main className="main-content !p-0">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/fine_management" element={<FineManagement />} />
                <Route
                  path="/account_management"
                  element={<AccountManagement />}
                />
                <Route path="/stock_management" element={<Stockmanagement />} />
                <Route path="/debt_management" element={<DebtManagement />} />
                <Route
                  path="/mortgage_management"
                  element={<MortgageManagement />}
                />
                <Route path="/log_out" element={<Logout />} />
                <Route path="*" element={<Dashboard />} />
              </Routes>
            </main>
          ) : (
            <Routes>
              <Route path="*" element={<AccountBox />} />
            </Routes>
          )}
        </div>
      </div>
      <ToastContainer />
    </Router>
  );
}

export default App;
